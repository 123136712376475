<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>OJT/MA History</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-activity-id" label="OJT/MA ID:">
                                    <b-input-group size="sm">
                                        <b-input id="search-activity-id" placeholder="OJT/MA ID" v-model="idFilter"/>
                                        <b-input-group-addon>
                                            <b-button @click="idFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-activity-title" label="OJT/MA Title:">
                                    <b-input-group size="sm">
                                        <b-input id="search-activity-title" placeholder="OJT/MA Title" v-model="titleFilter"/>
                                        <b-input-group-addon>
                                            <b-button @click="titleFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-activity-type" label="Type:">
                                    <b-input-group>
                                        <b-select id="search-activity-type" size="sm" v-model="typeFilter">
                                            <option :value="null">All</option>
                                            <option :value="'OJT'">OJT</option>
                                            <option :value="'MA'">MA</option>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button size="sm" @click="typeFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button variant="primary" @click="doSearch"><b-spinner v-if="searching" small type="grow"></b-spinner>Search</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="4">
                            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                        <b-col cols="8">
                            <b-form-group :label="'Records Per Page: ' + perPage">
                                <b-input type="range" v-model="perPage" min="10" max="100" step="10"></b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        Page {{ currentPage }} of {{ pages }}
                    </b-row>
                    <b-row>
                        <b-table small striped hover bordered stacked="md" head-variant="dark"
                                ref="searchResultsTable"
                                selectable
                                select-mode="single"
                                selected-variant="warning"
                                @row-selected="onRowSelected"
                                :items="results"
                                :fields="tableFields"
                                :per-page="perPage"
                                :current-page="currPage">
                        </b-table>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="3">
                            <b-form-group label-size="sm" label="Date Range:">
                                <picker-of-dates v-model="dateRange"
                                                 format="M/d/yyyy"
                                                 clearable range/>
                                </b-form-group>
                        </b-col>
                        <b-col cols="4" offset="5">
                            <b-form-group
                                    label-for="display-purposes"
                                    label="Selected OJT/MA:"
                                    :invalid-feedback="targetActivitySelectedMessage"
                                    :valid-feedback="targetActivitySelectedMessage"
                                    :state="isTargetActivitySelected">
                                <b-form-input
                                    id="display-purposes"
                                    size="lg"
                                    readonly
                                    v-model="selectedActivity"
                                    :state="isTargetActivitySelected"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right">
                            <b-button-group size="sm">
                                <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                                <b-button variant="info" @click="clearForm">Clear</b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store/store';
import _ from 'underscore';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import PickerOfDates from '@/components/shared/PickerOfDates';
import { trimToNull, date } from '@/util/formatters';
import download from '@/util/downloader';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";


@Component({
    components: {
        PickerOfDates
    }
})
export default class AuditOjtMaHistory extends Vue {

    isSearching = false;

    titleFilter = null;
    idFilter = null;
    typeFilter = null;

    currPage = 0;
    perPage = 10;

    reportDateRange = [null,null];
    reportTargetActivity = {};

    activities = [];

    get tableFields() {
        return [{
            key: 'displayId',
            label: 'ID',
            sortable: true
        }, {
            key: 'title',
            label: 'Title',
            sortable: true
        }, {
            key: 'description',
            label: 'Description',
            sortable: true
        }];
    }

    get searching() {
        return this.isSearching;
    }

    get dateRange() {
        return this.reportDateRange;
    }

    set dateRange(dates) {
        this.reportDateRange = dates;
    }

    get currentPage() {
        if (this.currPage < 1 || this.activities.length === 0) {
            return 1;
        }
        const numPagesNecessary = Math.ceil(this.activities.length / this.perPage);
        if (this.currPage > numPagesNecessary) {
            return numPagesNecessary;
        }

        return this.currPage;
    }

    set currentPage(page) {
        this.currPage = page;
    }

    get pages() {
        return Math.ceil(this.activities.length / this.perPage) || 1;
    }

    get totalRows() {
        return this.activities.length;
    }

    async doSearch() {
        this.isSearching = true;

        const ojts = this.$store.getters['activities/getOJTs'];
        const mas  = this.$store.getters['activities/getMAs'];
        const allActivities = _.union(ojts, mas);

        this.activities = _.chain(allActivities)
            .filter((activity) => {
                const filter = trimToNull(this.titleFilter);
                return null === filter || new RegExp(filter, 'i').test(activity.title);
            })
            .filter((activity) => {
                const filter = this.idFilter;
                return null === filter || new RegExp(filter, 'i').test(activity.displayId);
            })
            .filter((activity) => {
                const filter = this.typeFilter;
                return null === filter || (filter === 'OJT' && activity.isOJT()) || (filter === 'MA' && activity.isMA());
            })
            .sortBy((activity) => activity.displayId)
            .value();

        this.currentPage = 1;
        this.isSearching = false;
    }

    get results() {
        return this.activities;
    }

    onRowSelected(selected) {
        const activity = selected.pop();
        if (activity) {
            this.reportTargetActivity = activity;
        } else {
            this.reportTargetActivity = {};
        }
    }

    get isTargetActivitySelected() {
        return null;
        //return this.reportTargetContractor.id !== undefined;
    }

    get targetActivitySelectedMessage() {
        return this.isTargetActivitySelected ? '' : 'Please select an activity from the search results table';
    }

    get selectedActivity() {
        const activity = this.reportTargetActivity;
        if (activity.displayId === undefined) {
            return undefined;
        } else {
            return activity.displayId;
        }
    }

    clearFilters() {
        this.titleFilter = null;
        this.idFilter = null;
        this.typeFilter = null;
    }

    clearForm() {
        this.clearFilters();
        this.activities = [];
        this.reportDateRange = [null,null];
        this.reportTargetActivity = {};
    }

    isReady() {
        if (!this.reportTargetActivity.id) {
            alert("An activity must be selected");
            return false;
        } else if (!this.reportDateRange[0] || !this.reportDateRange[1]) {
            alert("Please select a date range");
            return false;
        }
        return true;
    }

    async getPdf() {
        if (!this.isReady()) {
            return;
        }
        const reqData = {};
        reqData.activityId = this.reportTargetActivity.id;
        reqData.fromDate = date(this.reportDateRange[0], date.DEFAULT_DATE_FORMAT) + " 00:00:00";
        reqData.toDate = date(this.reportDateRange[1], date.DEFAULT_DATE_FORMAT) + " 23:59:99";
        try {
            await download('POST', '/api/v1/report/audit-activity-history', JSON.stringify(reqData));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async beforeRouteEnter(to, from, next) {
        const deps = [
            'activities/loadOJTs',
            'activities/loadMAs'//,
            //'activities/loadCustomOJTs',
            //'activities/loadCustomMAs'
        ];
        _.each(deps, (action) => {
            store.dispatch(action)
                .catch(error => {
                    const vm = new Vue();
                    vm.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        next();
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('OJT/MA History', null, true));
    }
}
</script>


<style scoped>

</style>
